import React from "react";
import Layout from "../components/Layout";
import { Link } from "gatsby";
import { formatPrice } from "../utils/formatPrice";
import RuntimeOrderImage from "../components/RuntimeOrderImage";
import { isLoggedIn } from "../components/Auth";
import LoginError from "../components/LoginError";
import { gql, useQuery } from "@apollo/client";
import Loader from "./Loader";
import { getUser } from "../components/Auth";
import { useState } from "react";

const GET_CUSTOMER = gql`
  query ($handle: String!, $query: String) {
    customer(customerAccessToken: $handle) {
      id
      firstName
      lastName
      acceptsMarketing
      email
      phone
      orders(first: 1, query: $query) {
        edges {
          node {
            id
            processedAt
            currentTotalPrice {
              amount
              currencyCode
            }
            orderNumber
            email
            phone
            shippingAddress {
              address1
              address2
              city
              company
              country
              countryCodeV2
              firstName
              lastName
            }
            fulfillmentStatus
            lineItems(first: 100, reverse: false) {
              edges {
                node {
                  currentQuantity
                  title
                  customAttributes {
                    key
                    value
                  }
                  discountedTotalPrice {
                    amount
                    currencyCode
                  }
                  originalTotalPrice {
                    amount
                    currencyCode
                  }
                  variant {
                    image {
                      url
                      altText
                      originalSrc
                      width
                      height
                    }
                    title
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const SingleOrder = ({ name }) => {
  const token = getUser().token;
  const [orderData, setOrderData] = useState(null);

  const { loading } = useQuery(GET_CUSTOMER, {
    variables: { handle: token, query: `name:${name}` },
    onError: (error) => {
      console.log(error.message);
    },
    onCompleted: (data) => {
      setOrderData(data.customer.orders.edges[0].node);
    },
  });

  return (
    <Layout>
      {loading ? <Loader /> : !isLoggedIn ? <LoginError /> : 
        <div className="container mx-auto px-4">
          <h1 className="lg:text-5xl  font-bold leading-tight text-3xl my-8">
            My order
          </h1>

          <div className="col-12 mb-50">
            <div className="d-flex btn_back">
              <Link
                to="/my-orders"
                className="ms-2 font_yellow text-uppercase font_semibold no_underline"
              >
                Back to your orders
              </Link>
            </div>
          </div>
          {orderData && (
            <div className="py-14">
              <div className="flex justify-start item-start space-y-2 flex-col">
                <h2 className="text-3x lg:text-4xl font-semibold leading-7 lg:leading-9 text-gray-800">
                  Order {orderData.orderNumber}
                </h2>
                <p className="text-base dark:text-gray-300 font-medium leading-6 text-gray-600">
                  Processed at:{" "}
                  {new Date(orderData.processedAt).toLocaleDateString()}
                </p>
              </div>
              <div className="mt-10 flex flex-col xl:flex-row jusitfy-center items-stretch w-full xl:space-x-8 space-y-4 md:space-y-6 xl:space-y-0">
                <div className="flex flex-col justify-start items-start w-full space-y-4 md:space-y-6 xl:space-y-8">
                  <div className="flex flex-col justify-start items-start bg-gray-50 px-4 py-4 md:py-6 md:p-6 xl:p-8 w-full">
                    <p className="text-lg md:text-xl  font-semibold leading-6 xl:leading-5 text-gray-800">
                      Items
                    </p>
                    <div className="mt-4 md:mt-6 flex flex-col md:flex-row justify-start items-start md:items-center md:space-x-6 xl:space-x-8 w-full">
                      {orderData.lineItems.edges.map((item) => {
                        return (
                          <>
                            <div className="=w-full md:w-40">
                              <RuntimeOrderImage
                                storefrontProduct={item.node.variant.image}
                                className="w-full block"
                              />
                            </div>
                            <div className="border-b border-gray-200 md:flex-row flex-col flex justify-between items-start w-full pb-8 space-y-4 md:space-y-0">
                              <div className="w-full flex flex-col justify-start items-start space-y-8">
                                <h3 className="text-xl  xl:text-2xl font-semibold leading-6 text-gray-800">
                                  {item.node.title}
                                </h3>
                                <div className="flex justify-start items-start flex-col space-y-2">
                                  <p className="text-sm  leading-none text-gray-800">
                                    <span className=" text-gray-300">
                                      Option:{" "}
                                    </span>{" "}
                                    {item.node.variant.title}
                                  </p>
                                </div>
                              </div>
                              <div className="flex justify-between space-x-8 items-start w-full">
                                <p className="text-base xl:text-lg leading-6">
                                  {formatPrice(
                                    item.node.originalTotalPrice.currencyCode,
                                    item.node.originalTotalPrice.amount
                                  )}
                                  {item.node.discountedItemPrice && (
                                    <span className="text-red-300 line-through">
                                      {" "}
                                      {formatPrice(
                                        item.node.discountedItemPrice
                                          .currencyCode,
                                        item.node.discountedItemPrice.amount
                                      )}
                                    </span>
                                  )}
                                </p>
                                <p className="text-base  xl:text-lg leading-6 text-gray-800">
                                  {item.node.currentQuantity}
                                </p>
                                <p className="text-base  xl:text-lg font-semibold leading-6 text-gray-800">
                                {formatPrice(
                                    item.node.originalTotalPrice.currencyCode,
                                    item.node.originalTotalPrice.amount * item.node.currentQuantity
                                  )}
                                </p>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 dark:bg-gray-800 w-full xl:w-96 flex justify-between items-center md:items-start px-4 py-6 md:p-6 xl:p-8 flex-col">
                  <h3 className="text-xl dark:text-white font-semibold leading-5 text-gray-800">
                    Customer
                  </h3>
                  <div className="flex flex-col md:flex-row xl:flex-col justify-start items-stretch h-full w-full md:space-x-6 lg:space-x-8 xl:space-x-0">
                    <div className="flex flex-col justify-start items-start flex-shrink-0">
                      <div className="flex justify-center w-full md:justify-start items-center space-x-4 py-8 border-b border-gray-200">
                        <img
                          src="https://i.ibb.co/5TSg7f6/Rectangle-18.png"
                          alt="avatar"
                        />
                        <div className="flex justify-start items-start flex-col space-y-2">
                          <p className="text-base dark:text-white font-semibold leading-4 text-left text-gray-800">
                            {orderData.shippingAddress.firstName}{" "}
                            {orderData.shippingAddress.lastName}
                          </p>
                        </div>
                      </div>

                      <div class="flex justify-center text-gray-800 dark:text-white md:justify-start items-center space-x-4 py-4 border-b border-gray-200 w-full">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19 5H5C3.89543 5 3 5.89543 3 7V17C3 18.1046 3.89543 19 5 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5Z"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M3 7L12 13L21 7"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <p class="cursor-pointer text-sm leading-5 ">
                          {orderData.email}
                        </p>
                      </div>
                    </div>
                    <div class="flex justify-between xl:h-full items-stretch w-full flex-col mt-6 md:mt-0">
                      <div class="flex justify-center md:justify-start xl:flex-col flex-col md:space-x-6 lg:space-x-8 xl:space-x-0 space-y-4 xl:space-y-12 md:space-y-0 md:flex-row items-center md:items-start">
                        <div class="flex justify-center md:justify-start items-center md:items-start flex-col space-y-4 xl:mt-8">
                          <p class="text-base dark:text-white font-semibold leading-4 text-center md:text-left text-gray-800">
                            Shipping Address
                          </p>
                          {orderData?.shippingAddress?.address1 && (
                            <p class="w-48 lg:w-full dark:text-gray-300 xl:w-48 text-center md:text-left text-sm leading-5 text-gray-600">
                              Address: {orderData?.shippingAddress?.address1}
                            </p>
                          )}
                          {orderData?.shippingAddress?.address2 && (
                            <p class="w-48 lg:w-full dark:text-gray-300 xl:w-48 text-center md:text-left text-sm leading-5 text-gray-600">
                              {orderData?.shippingAddress?.address2}
                            </p>
                          )}
                          {orderData?.shippingAddress?.city && (
                            <p class="w-48 lg:w-full dark:text-gray-300 xl:w-48 text-center md:text-left text-sm leading-5 text-gray-600">
                              City: {orderData?.shippingAddress?.city}
                            </p>
                          )}
                          {orderData?.shippingAddress?.company && (
                            <p class="w-48 lg:w-full dark:text-gray-300 xl:w-48 text-center md:text-left text-sm leading-5 text-gray-600">
                              Company: {orderData?.shippingAddress?.company}
                            </p>
                          )}
                          {orderData?.shippingAddress?.country && (
                            <p class="w-48 lg:w-full dark:text-gray-300 xl:w-48 text-center md:text-left text-sm leading-5 text-gray-600">
                              Country: {orderData?.shippingAddress?.country}
                            </p>
                          )}
                        </div>
                      </div>
            
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
}
    </Layout>
  )
};

export default SingleOrder;
