import React from "react";
import SingleOrder from "../../components/SingleOrder";
const isBrowser = typeof window !== "undefined";


const Title = () => {
  

    let name;
          if (isBrowser) {
            name = window.location.hash;
          }
  return (
        <SingleOrder name={name} />
  );
};

export default Title;